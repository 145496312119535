export const fetchAppConfig = async () => {
  try {
    const response = await fetch('/pkce/app.config.json')
    const appConfig = await response.json()
    return appConfig
  }
  catch (e) {
    console.error("Error: ", e)
  }
}
