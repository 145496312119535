import React, { useContext, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

import { AuthContext, IAuthContext } from "./pkce";


type UserInfoProps = {
  QSF_USER_SAVE_PATH: string,
  QSF_PRE_LOGIN_PATH: string,
}

export const UserInfo = (props: UserInfoProps): JSX.Element => {
    const { token, loginInProgress, idToken } = useContext<IAuthContext>(AuthContext);

    useEffect(() => {
        if (!loginInProgress && token && idToken) {
            const decodedToken = jwtDecode(idToken) as any;
            const { PwCPPI } = decodedToken;
            
            const redirectUri = localStorage.getItem("ROCP_loginRedirect");
            localStorage.removeItem("ROCP_loginRedirect");

            if (props.QSF_USER_SAVE_PATH) {
                fetch(props.QSF_USER_SAVE_PATH, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'X-ID-TOKEN': idToken
                    }
                })
                .then(response => {
                    if (response.status === 200) {
                        localStorage.setItem("PwCPPI", PwCPPI);    
                        window.location.replace(redirectUri || props.QSF_PRE_LOGIN_PATH);
                    } else {
                        console.error("Unexpected status code:", response.status);
                    }
                })
                .catch(error => {
                    console.error("Error:", error);
                });
            } else {
                localStorage.setItem("PwCPPI", PwCPPI);
                window.location.replace(redirectUri || props.QSF_PRE_LOGIN_PATH);
            }
        }
    }, [loginInProgress, token, idToken, props.QSF_USER_SAVE_PATH, props.QSF_PRE_LOGIN_PATH]);

    // if (error) {
    //     return (
    //       <>
    //         <div style={{ color: 'red' }}>An error occurred during authentication: {error}</div>
    //         {/* <button onClick={() => logOut()}>Logout</button> */}
    //       </>
    //     )
    //   }

    return (
        <div className="spinner-wrapper">
            <p className="spinner" />
            <p>Loading...</p>
        </div>
    );
};