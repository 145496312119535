import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from "./pkce/AuthContext"
import { TAuthConfig } from "./pkce/Types"
import { fetchAppConfig } from "./config";
import { UserInfo } from './UserInfo';
import './spinner.css';

const getAuthConfig = (appConfig: any): TAuthConfig => {
  return {
    clientId: appConfig.AUTHZ_IDAM_CLIENT_ID,
    authorizationEndpoint: appConfig.PWC_AUTHENTICATION_URL,
    tokenEndpoint: appConfig.PWC_AUTHENTICATION_TOKEN_URL,
    redirectUri: appConfig.QSF_IDAM_CALLBACK_ENDPOINT,
    scope: appConfig.PWC_AUTHENTICATION_SCOPE,
    storage: 'local',
    // decodeToken: false,
    // autoLogin: false,
    extraTokenParameters: { 
        // We can add extra token params here.
    },
    preLogin: () => {},
    postLogin: () => {
        // moved redirect logic to UserInfo component to handle async nature of token in our app
    }
  }
}

const AuthProviderWrapper = () => {
  const [appConfig, setAppConfig] = useState<any>(undefined)
  
  useEffect(() => {

    
    const urlObject = new URL(window.location.href);
    const redirectValue = urlObject.searchParams.get('redirect');

    if (redirectValue) {
      localStorage.setItem("ROCP_loginRedirect", redirectValue);
    }

    fetchAppConfig()
      .then(setAppConfig)
  }, [])

  return (
    <>
      {appConfig && (
        <AuthProvider authConfig={getAuthConfig(appConfig)}>
          <UserInfo QSF_USER_SAVE_PATH={appConfig.QSF_USER_SAVE_PATH} QSF_PRE_LOGIN_PATH={appConfig.QSF_PRE_LOGIN_PATH} />
        </AuthProvider>
      )}
    </>
  )
}

const container = document.getElementById('root');
const root = createRoot(container as any);
root.render(<AuthProviderWrapper />,);

export default AuthProviderWrapper